import { render, staticRenderFns } from "./GlobalLoadingSpinner.view.vue?vue&type=template&id=01dd3988&"
import script from "./GlobalLoadingSpinner.view.vue?vue&type=script&lang=js&"
export * from "./GlobalLoadingSpinner.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01dd3988')) {
      api.createRecord('01dd3988', component.options)
    } else {
      api.reload('01dd3988', component.options)
    }
    module.hot.accept("./GlobalLoadingSpinner.view.vue?vue&type=template&id=01dd3988&", function () {
      api.rerender('01dd3988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/overlays/popups/common/GlobalLoadingSpinner.view.vue"
export default component.exports