import { render, staticRenderFns } from "./SignContractAnnexPopup.view.vue?vue&type=template&id=2c5669cc&"
import script from "./SignContractAnnexPopup.view.vue?vue&type=script&lang=js&"
export * from "./SignContractAnnexPopup.view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-old/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c5669cc')) {
      api.createRecord('2c5669cc', component.options)
    } else {
      api.reload('2c5669cc', component.options)
    }
    module.hot.accept("./SignContractAnnexPopup.view.vue?vue&type=template&id=2c5669cc&", function () {
      api.rerender('2c5669cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rearchitected/themes/atmo/views/overlays/popups/purchases/contract/annex/sign/SignContractAnnexPopup.view.vue"
export default component.exports